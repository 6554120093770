import React, { useEffect } from 'react';
import { useColorMode } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';

import { useIntl } from 'context/IntlContext';
import { AmazonBanner } from 'components/AmazonBanner';
import TopCTA from '../../components/TopCTA';
import Marketplace from './Marketplace';
import DigitalAccountSection from './DigitalAccountSection';
import WeBelieveFree from './WeBelieveFree';
import YouCanTrust from './YouCanTrust';
import Section from '../../components/Section';
import Seo from '../../components/SEO';
import FAQHome from '../../components/FAQHome';
import Thumbnail from '../../images/thumbnail.png';
import Anna from '../../videos/anna-es.mp4';

const PAGE_COLOR_MODE = 'default';

const BannerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;

  a {
    position: absolute;
  }

  @media (max-width: 1024px) {
    max-width: calc(100% - 32px);
  }
`;

const Home = () => {
  const { t } = useIntl();
  const [colorMode, setColorMode] = useColorMode();
  const { locale } = useIntl();
  const isSpanishPage = locale === 'es';

  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
  }, [colorMode, setColorMode]);

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <TopCTA
        heading={t('homepage.home.hero.title')}
        subtitle={t('homepage.home.hero.subtitle')}
        // image={
        //   <StaticImage
        //     src="../../images/hero-image.png"
        //     alt="Hero section image"
        //   />
        // }
        video= {Anna}
        thumbnail={Thumbnail}
      />
      {/* {isSpanishPage && (
        <BannerContainer>
          <AmazonBanner href="/marketplace" />
        </BannerContainer>
      )} */}
      <Marketplace />
      <Section>
        <DigitalAccountSection />
      </Section>
      <Section>
        <WeBelieveFree />
      </Section>
      <Section>
        <YouCanTrust />
      </Section>
      <Section noDivider>
        <FAQHome />
      </Section>
    </>
  );
};

export default Home;
