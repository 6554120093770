import React, { FC, ReactElement } from 'react';
import ReactPlayer from 'react-player';
import { Box, Text, Heading, Grid } from 'components';
import Section from '../Section';
import { useIntl } from 'react-intl';
interface TopCTAProps {
  video?: string;
  image?: ReactElement;
  heading: string;
  subtitle: string;
  thumbnail?: string;
}

const TopCTA: FC<TopCTAProps> = ({ image, video, heading, subtitle, children, thumbnail }) => {
  const { locale } = useIntl();

  const isSpanishPage = locale === 'es';

  return (
    <Section
      outterSX={{ bg: 'tertiaryBackground' }}
      innerSX={{
        pb: [0, 0, 0, 0, 0],
      }}
      noDivider
    >
      <Grid gap={7} columns={['3fr', '3fr', '3fr', '1fr 1.3fr']}>
        <Box>
          <Heading
            as="h1"
            sx={{
              variant: 'text.heroTitle',
              color: 'tertiary',
              mb: [0, 0, 0, 70, 70],
            }}
          >
            {heading}
          </Heading>
          <Text
            as="p"
            variant="underHero"
            sx={{
              color: 'tertiary',
              opacity: '0.8',
            }}
          >
            {subtitle}
          </Text>
        </Box>
        <Box
          sx={{
            height: ['auto', 'auto', 'auto', 514, 514],
            display: ['inline-block', 'inline-block', 'inline-block', 'inline-block', 'inline-block'],
            position: 'relative',
            width: '100%',
            minHeight: '350px', 
            verticalAlign: 'middle',
            overflow: 'hidden',
            '& > svg': {
              verticalAlign: 'bottom',
            },
          }}
        > 
        {
          image &&
          <Box sx={{ position: 'absolute', bottom: 0 }}>{image}</Box>
        }
        {
          video &&
          <Box className='player-wrapper'>
            <ReactPlayer
            className='react-player fixed-bottom'
            url= {video}
            width= '640px'
            height='360px'
            controls = {true}
            config={{
              file: { 
                attributes: { 
                  poster: thumbnail
                } 
              } 
            }}
          />
          </Box>
        }
        </Box>
      </Grid>
      {children}
    </Section>
  );
};

export default TopCTA;
