import React from 'react';
import {
  Flex,
  Button,
  Text,
  Box,
  Image,
  Grid,
} from 'components';
import Link from 'components/Link';

import { useIntl } from 'context/IntlContext';

import fondos from '../../../Marketplace/TopSection/TabContent/Fondos/icons/fondos.jpg';
import { TabTitle } from '../styled';

const Fondos = () => {
  const { t } = useIntl();
  return (
    <Flex
      sx={{
        justifyContent: 'stretch',
        alignItems: 'center',
        flexDirection: ['column', 'column', 'column', 'row'],
        marginTop: '80px',
        '@media screen and (max-width: 768px)': {
          marginTop: '44px'
        }
      }}
    >
      <Grid
        columns={['1fr', '1fr', '1fr', '1fr 1fr']}
        sx={{
          columnGap: '72px',
          gridRowGap: 0,
        }}
      >
        <Box
          sx={{
            gridColumn: '1',
            gridRow: '1',
            maxWidth: ['100%', '100%', '100%'],
            pr: ['0', '0', '0', '0'],
          }}
        >
          <TabTitle
            sx={{
              mb: [7],
            }}
          >
            {t('homepage.marketplace.broker.hero.title')}
          </TabTitle>
          <Text as="p" variant="underHero" sx={{ mb: 9 }}>
            {t('homepage.marketplace.broker.hero.subtitle')}
          </Text>
          <Box
            sx={{
              textAlign: 'initial',
              display: ['initial', 'initial', 'initial', 'none'],
            }}
          >
            <Image
              src={fondos}
              alt="Fondos"
              sx={{
                width: 'inherit',
                height: 'auto',
                m: '0 auto',
                maxWidth: '100%',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: ['center'],
              flexDirection: ['column', 'column', 'column', 'row'],
            }}
          >
            <Button
              as={Link}
              variant="primary"
              to={t('homepage.marketplace.broker.button.cliente_access.link')}
              mr={[0, 0, 0, 3]}
              my={2}
            >
              {t('homepage.marketplace.broker.button.cliente_access')}
            </Button>
            <Button
              my={2}
              as={Link}
              to="/marketplace#fondos"
              variant="secondary"
            >
              {t('global.buttons.learn_more')}
            </Button>
          </Box>
          <Text
            sx={{
              color: '#233143',
              fontSize: '12px',
              lineHeight: '24px',
              marginTop: '40px',
              textAlign: 'left',
            }}>
            {t('marketplace.provider.cta.warning')}
          </Text>
        </Box>
        <Box
          sx={{
            textAlign: 'initial',
            display: ['none', 'none', 'none', 'initial'],
          }}
        >
          <Image
            src={fondos}
            alt="Fondos"
            sx={{
              display: 'block',
              width: '533px',
              height: '393px',
              margin: '0 0 0 80px',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Grid>
    </Flex>
  );
};

export default Fondos;
