import React, { useEffect, useState, useContext } from 'react';

import { Text } from 'components';
import { useIntl } from 'context/IntlContext';

import { useLocation } from 'context/LocationContext';
import ViewerContext from 'context/ViewerContext';
import Tabs from '../../../components/MegaTabs';
import Tab from '../../../components/MegaTabs/Tab';
import Section from '../../../components/Section';

import productsJSON from '../../../assets/products.json';
import TabContent from './TabContent';

interface Product {
  id: string;
  titleKey: string;
  countryRestrictions: string[];
  localeRestrictions: string[];
  hidden: boolean;
}

const Marketplace = () => {
  const { t, locale } = useIntl();
  const { location } = useLocation();
  const { countryCode, locales } = useContext(ViewerContext);

  const [products, setProducts] = useState<Product[]>([]);

  const isSpanishPage = locale === 'es';

  useEffect(() => {
    setProducts(
      productsJSON.filter((product: Product) => {
        return (
          !product.countryRestrictions.includes(countryCode) &&
          !product.localeRestrictions.includes(locale) && 
          !product.hidden
        );
      }),
    );
  }, [locale]);

  return (
    <Section outterSX={{ marginTop: isSpanishPage ? [89, 99, 109, 0, 0] : 0 }}>
      <Text as="h2" variant="heroTitle">
        {t('homepage.home.marketplace_section.title')}
      </Text>
      <Text variant="underHero" sx={{ pb: 10, pt: 7 }}>
        {t('homepage.home.marketplace_section.subtitle')}
      </Text>
      <Tabs activeTab={location?.hash}>
        {products.map(({ id, titleKey }: Product) => {
          return (
            <Tab key={id} hash={`#${id}`} id={id} title={t(titleKey)}>
              {<TabContent content={id} />}
            </Tab>
          );
        })}
      </Tabs>
    </Section>
  );
};

export default Marketplace;
